.hero-wrapper{

    background-image: url(../../Assets/Hero-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    // min-height: 100vh;
     
    @media (max-width:768px)  {
        // padding-bottom: 50px;
        // min-height: 60vh; 
       }
    
    @media (max-width:576px)  {
        // padding-bottom: 80px;
        min-height: fit-content; 
       }

    >*{
        max-width: 70%;
        @media (max-width:768px)  {
        
          max-width: 80%;  
           }
           @media (max-width:576px)  {
        max-width: 90%;  
           }
    }

    .banner
    {margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
   height: 90vh;

//    @media  (max-width:1180px){
//     height: 50vw;
//      }

   @media (max-width:992px)  {
        padding: 90px 0;
    height: fit-content;
      
     }

   @media (max-width:768px)  {
        padding: 60px;
    // height: 60vh;

     }
     @media (max-width:576px)  {
        padding: 40px;
        // height: 60vh;
          
         }
       
    }
}