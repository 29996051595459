.offices{

    >div{
        width: 75%;
        margin: 0 auto;
        background: url(../../Assets/office-bg.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @media (max-width:768px)  {
        
           width: 80%; 
           }
           @media (max-width:576px)  {
        
            width: 90%;
             }
    }

    h1{
        // line-height: 120px;
    }
    p{
    font-family: Nunito Sans;
font-size: var(--f6);
font-weight: 300;
line-height: 54px;
// letter-spacing: -0.5px;
text-align: center;
margin-bottom: 40px;
@media (max-width:1546px){
    margin-bottom: 30px;
            }
            @media (max-width:992px){
                margin-bottom: 20px;
                line-height: 30px;   
            }   
}


    .card-wrapper{
        display: flex;
        justify-content: center;
        align-items: stretch; 
        margin-top: 50px;
        gap: 48px;
      padding-bottom: 175px;

      @media (max-width:1546px){
      gap: 35px;
      padding-bottom: 120px;
                }
                @media (max-width:1280px){
                    
                   padding-bottom: 100px;
               } 

                @media (max-width:1050px)  {
        
                   gap: 15px;
                     }
                @media (max-width:992px){
                   gap: 20px;
                   margin-top: 30px;
                   padding-bottom: 30px;
                   flex-wrap: wrap;
                }   
                @media (max-width:767px)  {
        gap: 9px;
        padding-bottom: 70px; 
                   }
                   @media (max-width:576px)  {
        padding-bottom: 30px;
                  flex-wrap: wrap; 
                  margin-top: 20px;
                   } 


        .card{color: #fff;
            flex: 1;
            background: #0F2028;
            box-shadow: 0px 8px 16px -8px #0000004D;
border-radius: 20px;
            box-shadow: 0px 13px 27px -5px #32325D40;
max-width: 240px; 
width: 100%;  
padding: 25px 12px;
animation: backInUp 2s both;
animation-timeline: view();
animation-range: cover cover 25% ;

max-height: 185px;

@media (max-width:1050px)  {
        
 padding: 15px 10px;
     }


@media (max-width:992px){
    min-height: 115px;
    max-width: 150px;
    min-width: 150px; 
          padding: 15px 8px;         
}   
@media (max-width:767px)  {
        padding: 12px 10px;
   border-radius: 14px; 
   }
   @media (max-width:576px)  {
       max-width: 150px; 
  min-width: 135px; 

   } 

div{width: 35px;
    margin: 0 auto;
    text-align: center;

    @media (max-width:1280px){
        width: 30px;           
    }   

    @media (max-width:992px){
         width: 24px;          
    }   
    img{
        width: 100%;
    }
}

h5{
    font-family: Nunito Sans;
font-size: var(--f12);
font-weight: 700;
line-height: 51.43px;
text-align: center;
@media (max-width:1546px){
  line-height: 35px;
              }
              @media (max-width:767px)  {
        
                line-height: 20px;   
                margin-top: 5px;
                margin-bottom: 5px;
               }

}

p{
    font-family: DM Sans;
font-size: var(--f2 );
font-weight: 300;
line-height: 18.23px;
text-align: center;
margin-bottom: 0;
@media (max-width:992px){
    line-height: 13px;               
}   

}

        }
    }
}