.currencies {
  > div {
    // overflow-x: hidden;
    max-width: 70%;
    display: flex;
    gap: 115px;
    margin: 0 auto;
    padding: 120px 0;
    align-items: center;
    justify-content: center;
 
    @media (max-width:1546px){
     gap: 90px;
              }
              @media (max-width:1280px){
                gap: 70px;
                padding: 100px 0px;
           } 
              @media (max-width:992px){
                   gap: 50px;
                   padding: 80px 0px;
              }   
              @media (max-width:768px)  {
                padding: 60px 0;
        max-width: 80%;  
               }
               @media (max-width:576px)  {
        
              max-width: 90%;
              padding: 35px 0;
              flex-direction: column-reverse;   
               }

    .left {height: fit-content;
      border-radius: 8px;
    //   max-width: 552px;
      width: 45%;
      box-shadow: 0px 8px 16px -8px #00000099;
      animation:backInLeft 3s forwards;
      animation-timeline: view();
      animation-range:  entry 0%   ;

      @media (max-width:576px)  {
        
        width: 100%; 
       }

      h4 {
        background: #0f2028;
        padding: 25px 0;
        color: #edfd72;
        font-family: DM Sans;
        font-size: var(--f6 );
        font-weight: 500;
        line-height: 46.87px;
        text-align: center;
        border-radius: 8px 8px 0 0;
        @media (max-width:1546px){
          line-height: 34px;
                  }

                  @media (max-width:992px){
                   padding: 16px 0;
                  }   
                  @media (max-width:767px)  {
        
                    padding: 10px 0;  
                   }
                   
                         
       
      }

      table {
        padding:0 25px;
        width: 100%;
background: #f8f8f8;
        text-align: left;
        border-radius: 8px;
        border-spacing: 0 40px;
        @media (max-width:1546px)  {
        border-spacing:0 30px;
        
        }
        @media (max-width:1280px)  {
        border-spacing:0 25px;
        
        }
        @media (max-width:992px){
         border-spacing: 0 15px;          
        }   
        td{font-size: var(--f5 );
            font-family: DM Sans;
            // margin-bottom: 30px;
        }

        td:nth-child(1),
        td:nth-child(3) {
          width: 20%;
        }
        td:nth-child(3) {
           text-align: right;
          
          }

        td:nth-child(2) {
            padding: 0 35px;
          border-left: 1px solid #9ca6b6;
          border-right: 1px solid #9ca6b6;
          @media (max-width:1280px){
                   padding: 0 30px;
          }   

          @media (max-width:992px){
                padding: 0 20px;   
          }   
          @media (max-width:767px)  {
        
           padding: 0 8px;   
           }
           @media (max-width:576px)  {
        
           padding: 0 25px;;  
           } 
        }
      }
    }

    .right{
      animation:backInRight 3s forwards;
      animation-timeline: view();
      animation-range:  entry 0%   ;
width: 55%;
@media (max-width:576px)  {
 width: 100%; 
 }
        h2{
            font-family: Nunito Sans;
font-size: var(--f10);
font-weight: 700;
line-height: 102px;
letter-spacing: 0.005em;
text-align: left;

@media (max-width:1546px){
  line-height: 70px;
          }
          @media (max-width:1280px)  {
        
        line-height: 60px;
          }
          @media (max-width:992px){
                line-height: 45px;   
          }   
          @media (max-width:767px)  {
        
            line-height: 40px;   
           }


        }
        p{
            font-family: DM Sans;
font-size: var(--f4 );
font-weight: 400;
line-height: 30.6px;
text-align: left;
color: #667778;
margin-top: 24px;
margin-bottom: 45px;

@media (max-width:1280px)  {
        line-height: 25px;
        margin-top: 16px;
        margin-bottom: 37px;
        
}
@media (max-width:992px){
   line-height: 18px;
   margin-top: 13px;
   margin-bottom: 25px;                
}   

        }
    }
  }
}
