.footer{
    padding-top: 120px;
    @media (max-width:1546px){
        padding-top: 50px;
                }
                @media (max-width:992px){
                   padding-top: 50px;
                }   
                @media (max-width:768px)  {
        
                   padding-top: 20px;
                      
                     }

    .icons{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:50px;
        font-size: 32px;
        @media (max-width:1546px){
            font-size: 25px;
                    }
                    @media (max-width:1280px)  {
        font-size: 20px;
        gap: 35px;
                    }
    }

    .terms{color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Lato;
font-size: var(--f3 );
font-weight: 700;
line-height: 22.86px;
text-align: center;
margin-top: 50px;
        gap: 30px;
        background: #0F2028;
        height: 63px;
        @media (max-width:1546px){
  margin-top: 40;
  height: 50px;
        }
        @media (max-width:1280px)  {
        margin-top: 30px;
        height: 40px;
        
        }
        @media (max-width:992px){
            margin-top: 20px;
            height: 34px;       
        } 
        @media (max-width:576px)  {
        
           gap: 15px;  
           }   
    }
    p{
        font-family: DM Sans;
font-size: var(--f2 );
font-weight: 500;
line-height: 20px;
text-align: center;
color: #0F2028;
margin-top: 28px;
margin-bottom: 18px;

@media (max-width:1546px){
  
margin-top: 20px;
margin-bottom: 15px;}
    
    @media (max-width:1280px)  {
        
       margin-top: 15px;
       margin-bottom: 10px; 
    }
    @media (max-width:992px){
             line-height: 8px;      
    }   
}
}