.touch{padding: 120px 0;
color: #0F2028;
@media (max-width:1280px){
   padding: 100px 0;
} 
@media (max-width:992px){
    padding: 75px 0 80px 0;               
}   
@media (max-width:767px)  {
        
  padding: 50px 0 60px 0;
   }
   @media (max-width:576px)  {
        
    padding: 35px 0;
     }

p{
    font-family: Nunito Sans;
font-size: var(--f6);
font-weight: 300;
line-height: 54px;
// letter-spacing: -0.5px;
text-align: center;
margin-bottom: 40px;
@media (max-width:1546px){
    margin-bottom: 30px;
            }
            @media (max-width:992px){
                margin-bottom: 20px;
                line-height: 30px;   
            }   
}

div{padding: 60px 0 50px 0 ;
    box-shadow: 0px 8px 16px -8px #0000004D;
border-radius: 40px;
box-shadow: 0px 13px 27px -5px #32325D40;
background: #F8F8F8;
max-width: 847px;
margin: 0 auto;
@media (max-width:1546px){
    max-width: 680px;
    padding-top: 35px;
    padding-bottom: 40px;
            }
            @media (max-width:1280px)  {
        max-width: 580px;
        padding-bottom: 30px;
        padding-top: 35px;
        border-radius: 30px;
        
            }
            @media (max-width:992px){
              max-width: 520px;
              padding-bottom: 25px;
              padding-top: 30px; 
              border-radius: 20px;    
            }   
            @media (max-width:767px)  {
        
               max-width: 420px;   
               }
               @media (max-width:576px)  {
        
               width: 85%; 
               } 

form{
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (max-width:1280px)  {
        width: 82%;
        
    }
    @media (max-width:992px){
        width: 85%;           
    }   
   

    ::placeholder{font-family: Nunito Sans;
        font-size: var(--f2 );
        font-weight: 400;
        line-height: 50.5px;
        text-align: left;
        color:  #0F2028;
        // padding-left: 13px;
        }

input{background:#f7f7f7;
    border: 1px solid #E7E8E9;
    box-shadow: 0px 202.83px 490.55px 0px #7878A82B;
outline: none;
border-radius: 20px;
height: 70px;
margin-bottom: 30px;
padding: 0 12px;
font-family: Nunito Sans;
        font-size: var(--f2 );
        font-weight: 500;
        line-height: 50.5px;
        text-align: left;
        color:  #0F2028;
@media (max-width:1546px){
    height: 55px;
    margin-bottom: 25px;
            }
            @media (max-width:1280px)  {
        
        height: 43px;
        margin-bottom: 25px;
        border-radius: 15px;
            }
            @media (max-width:992px){
                height: 38px;
                margin-bottom: 20px;   
            }   
}

button{
   
   max-width: 250px;
   width: 100%;
   margin: 0 auto;
   margin-top: 20px;
   @media (max-width:1546px){
   margin-top: 5px;
   max-width: 200px;
            }
            @media (max-width:1280px)  {
        max-width: 180px;
        
            }
            @media (max-width:992px){
                max-width: 150px;
            }   
}



}


}
}