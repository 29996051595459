.payment-solution {
  > div {
    max-width: 70%;
    margin: 0 auto;
    padding: 120px 0;
    @media (max-width:1280px){
    
      padding: 100px 0px;
 } 
    @media (max-width:992px){
               padding: 80px 0;    
    }   
    @media (max-width:768px)  {
      padding: 50px 0;
     max-width: 80%; 
     }
     @media (max-width:576px)  {
        padding: 35px 0;
    max-width: 90%; 
     } 
  }



  .cards {
    // overflow-x: hidden;
    // padding: 30px;
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    // margin-bottom: 100px;
    @media (max-width:1546px){
    margin-top: 50px;
              }
              @media (max-width:992px){
                  margin-top: 40px; 
                  // margin-bottom: 40px; 
              }   
              //  @media (max-width:767px)  {
              //   margin-bottom: 40px; 
              
              //   }
               @media (max-width:576px)  {
        align-items: center;
               flex-direction: column; 
               } 
            

    .card {
      background: #f8f8f8;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 8px 16px -8px #0000004d;
      max-width: 440px;
      width: 100%;
      box-shadow: 0px 13px 27px -5px #32325d40;
      border-radius: 40px;
      padding: 35px;
      transition: 0.3s;
      // &:hover{
      //   transform: scale(1.05);
       
      // }
      @media (max-width:1546px){
        padding: 25px;         
    } 


      @media (max-width:1280px){
          padding: 20px;         
      }   
      @media (max-width:992px){
           padding:15px 18px;        
      }  
      @media (max-width:768px){
        padding:15px 12px;         
    } 


      @media (max-width:576px)  {
        padding: 20px;
        width: 70%;
       }  


      div {
        max-height: 110px;
        max-width: 110px;
        @media (max-width:1280px)  {
        max-width: 90px;
        
        }
        @media (max-width:992px){
                  max-width: 70px; 
        }   

        img {
          width: 100%;
        }
      }
      h5 {
        font-family: Nunito Sans;
        font-size: var(--f8);
        font-weight: 700;
        line-height: 66px;
        // letter-spacing: -2.5010528564453125px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 10px;
        @media (max-width:1546px){
          line-height: 50px
                  }
                  @media (max-width:992px){
                   line-height: 30px;
                   margin-top: 15px;
                  }   
      }

      p {
        font-family: DM Sans;
        font-size: var(--f5 );
        font-weight: 500;
        line-height: 39px;
        // letter-spacing: -0.6565263867378235px;
        text-align: center;
        @media (max-width:1546px){
          line-height: 28px;
                  }
                  @media (max-width:992px){
                   line-height: 18px;
                  }   
      }

 


    }
    .card:nth-child(1) {  z-index: -1;
   
  transform: rotate(-5.62deg);
    
      // &:hover,
      // &.active,
      // &.focus {
      //   transform: scale(1.05) rotate(-5.62deg);
      // }

      }
      .card:nth-child(2) {color: #fff;
       background: #0F2028;
       
      animation: backInRight forwards ;
      animation-timeline: view();
      animation-range: cover cover 40% ;
    animation-direction: alternate;
      
       h5{
        color: #EDFD72;
       }
      }

      .card:nth-child(3) {
        transform: rotate(5.62deg);
        // &:hover,.active, .focus{
        //   transform: scale(1.05) rotate(5.62deg) ;}
      }


}

.payment{
    padding: 120px 0 0 0 ;
    display: flex;
    justify-content: center;
    align-items: center;
gap: 140px;
@media (max-width:1280px){
    gap: 100px; 
    padding: 100px 0 0 0;              
}   
@media (max-width:992px){
            gap: 80px;   
            padding-top: 80px;    
}   
@media (max-width:768px)  {
       padding-top: 60px; 
 gap: 45px;
   } 

@media (max-width:576px)  {
        padding-top: 40px;
        gap: 35px;
flex-direction: column-reverse;  
 } 

    .left{
       width: 50%;
       @media (max-width:576px)  {
        
        width: 70%;  
       } 
        
        img{
            width: 100%;
        }
    }

    .right{
        width: 50%;
        @media (max-width:576px)  {
        
         width: 100%;  
         } 
        h3{
            text-align: left;
        }
        p{margin: 20px 0;
            font-family: DM Sans;
font-size: var(--f5 );
font-weight: 500;
line-height: 39px;
// letter-spacing: -0.6565263867378235px;
text-align: left;
color:#0F2028;
@media (max-width:1546px){
  line-height: 30px;
          }
          @media (max-width:1280px)  {
        line-height: 25px;
   
        
          }
          @media (max-width:576px)  {
            line-height: 22px;
       
            
              }
          

        }

        .check{
            margin-bottom: 60px;
            @media (max-width:1280px)  {
        
        margin-bottom: 35px;
            }
            p{
              color: #667078;
              margin: 10px 0;
              @media (max-width:1280px)  {
        
        margin: 5px 0;
              }
              img{width: 5%;
                margin-right: 14px;
              }
            }
        }
    }
}
}
