@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

body{
  max-width: 1920px;
overflow-x:hidden !important;
  

}
::-webkit-scrollbar {
  display: none; }

  a{
    text-decoration: none;
    color: inherit;
  }

button{
  border: 1px solid  #0F2028;
  border-radius: 8px;
  padding: 17px 40px;
 color: #EDFD72;
  background: #0F2028;
  box-shadow: 0px 5.11px 12.77px 0px #E7E7FB;
  font-family: Nunito Sans;
  font-size: var(--f5);
  font-weight: 700;
  line-height: 24.88px;
  width: fit-content;
  /* letter-spacing: -0.6385666728019714px; */
  text-align: center;
  cursor: pointer;
  transition: 0.5s ease-in-out;

  animation: fadeInUp .5s both;
  animation-timeline: view();
  animation-range: cover cover 40%;


&:hover{
 color:  #0F2028;
 background: transparent;
  border: 1px solid  #0F2028; ;
}

  @media (max-width:1546px){
padding: 10px 40px;
  }
  @media (max-width:1280px)  {
        padding: 8px 35px;
        
  }
  @media (max-width:767px)  {
    padding: 7px 25px;
    
}
}

  h1{font-family: Nunito Sans;
    font-size: var(--f11);
    font-weight: 700;
    line-height: 96px;
    letter-spacing: -2.5010528564453125px;
    text-align: center;
    text-transform: Capitalize;
    animation:fadeInUp 2s both;
    animation-timeline: view();
    animation-range:  cover cover 40% ;
    
    span{
        color: #EDFD72;
    }

    @media (max-width:1546px){
line-height: 85px;
    }
    @media (max-width:1280px)  {
        
     line-height: 68px;   
    }
    @media (max-width:768px)  {
        
      line-height: 60px;   
     }
     @media (max-width:576px)  {
        
      line-height: 50px;
       } 
}

h3{animation:fadeInDown .5s both;
  animation-timeline: view();
  animation-range:cover  cover 40% ;
  font-family: Nunito Sans;
font-size: var(--f9);
font-weight: 700;
line-height: 84px;
/* letter-spacing: -2.5010528564453125px; */
text-align: center;


span{
color: #EDFD72;
}
@media (max-width:1546px){
  line-height: 55px;
          }
          @media (max-width:1280px)  {
        
        line-height: 50px;
          }

          @media (max-width:767px)  {
        
            line-height: 40px;   
           }


}

p{
  @media (max-width:1546px){
line-height: 17px;
  }
}

:root{

  --f1:10px;
  --f2:14px;
  --f3:16px;
  --f4:18px;
  --f5:20px;
  --f6:24px;
  --f7:36px;
  --f8:45px;
  --f9:60px;
  --f10:68px;
  --f11:96px;
  --f12:30px;



  @media screen and (max-width:1545px) {
    --f1:9px;
    --f2:11px;
    --f3:12px;
    --f4:15px;
    --f5:16px;
    --f6:20px;
--f7:32px;
--f8:33px;
--f9:45px;
--f10:50px;
    --f11:73px;
    --f12:22px;
    
  }
  @media screen and (max-width:1280px){
    --f1:8px;
    --f2:9px;
    --f3:10px;
    --f4:12px;
    --f5:14px;
    --f6:16px;
--f7:27px;
--f8:29px;
--f9:39px;
--f10:41px;
    --f11:60px;
    --f12:18px;
  } 

  @media screen and (max-width:992px){
    /* --f1:7px;
    --f2:8px;
    --f3:9px;
    --f4:11px;*/
    --f5:13px;
    --f6:14px; 
--f7:24px;
--f8:25px;
--f9:33px;
--f10:35px;
    --f11:50px;
    --f12:16px;
  } 

  /* @media screen and (max-width:767px){
    --f1:7px;
    --f2:8px;
    --f3:9px;
    --f4:11px;
    --f5:11px;
    --f6:13px;
--f7:22px;
--f8:23px;
--f9:30px;
--f10:31px;
    --f11:45px;
    --f12:15px;
  }  */

  @media screen and (max-width:576px){
    /* --f1:7px;
    --f2:8px;
    --f3:9px;
    --f4:11px;
    --f5:11px;
    --f6:13px;
--f7:22px;
--f8:23px;
--f9:30px; */
--f10:30px;
    --f11:44px;
    --f12:15px;
  } 



}