.wallet{background: #0F2028;
    color: #fff;

    >div{
        width: 70%;
        margin: 0 auto;
        padding: 100px 0 120px 0;
        @media (max-width:1280px){
         padding: 90px 0 100px 0;
       } 
       @media (max-width:992px){
        
        padding: 70px 0px 80px 0;
   } 

        @media (max-width:768px)  {
        padding: 55px 0 60px 0;
          width: 80%;
           }
           @media (max-width:576px)  {
        padding: 35px 0 35px 0;
            width: 90%;
             }

    }
    h1{
        max-width: 730px;
        margin: 0 auto;
        // line-height: 120px;
        @media (max-width:1546px){
            // line-height:100px;
                    }
                    @media (max-width:1280px)  {
        max-width: 600px;
        // line-height: 80px;
                    }
                    @media (max-width:767px)  {
        
                        // line-height: 50px;   
                       }
       
    }
    p{
        font-family: DM Sans;
font-size: var(--f5 );
font-weight: 400;
line-height: 29.7px;
text-align: center;
max-width: 755px;
margin: 0 auto;
margin-top: 30px;
margin-bottom: 75px;
@media (max-width:1546px){
   max-width: 680px;
   margin-top: 20px;
   margin-bottom: 55px;
   line-height: 24px;
            }
            @media (max-width:1280px)  {
        max-width: 600px;
        line-height: 20px;
        margin-bottom: 45px;
            }
            @media (max-width:992px){
                 max-width: 520px;
                 margin-top: 10px;  
                 margin-bottom: 40px;
            }   

    }

    .box-wrapper{
        display: flex;
        column-gap: 60px;
        row-gap: 36px;
        flex-wrap: no-wrap;
        overflow-x: scroll;
        @media (max-width:1280px)  {
        
        column-gap: 35px;
        row-gap: 25px;
        }
        @media (max-width:1100px)  {
        
           column-gap: 15px;
             }
        @media (max-width:992px){
                 column-gap: 20px;  
                 row-gap: 16px;
        }   

        .box {flex: 1;
            /* Background gradient */
            background: linear-gradient(186.47deg, rgba(255, 253, 253, 0.4) -20.85%, rgba(255, 253, 253, 0.2) -20.83%, rgba(255, 255, 255, 0) 116.4%);
padding:  15px;
            display: flex;
            gap: 12px;
            align-items: center;
      
        
            /* Border settings */
            border: 1px solid transparent; /* Adjust border width as needed */
            border-image: linear-gradient(180deg, rgba(255, 255, 255, 0.58) -8.13%, rgba(255, 255, 255, 0) 92.62%);
            border-image-slice: 1;
            border-radius: 4px;
            @media (max-width:1280px)  {
        padding: 5px;
        
            }
            @media (max-width:1546px)  {
        padding: 8px;
                gap: 8px;  
               } 

            @media (max-width:992px)  {
        
                gap: 4px; 
               } 
                
        


            .image{
                width: 33px;
                max-height: 33px;
                @media (max-width:1280px)  {
              width: 25px;
        
                }

                img{
                    width: 100%;
                }
            }

            .name{
                font-family: Roboto;
font-size: var(--f3 );
font-weight: 400;
line-height: 18.75px;
text-align: left;

p{margin-top: 3px;
    font-family: Roboto;
font-size: var(--f1 );
font-weight: 400;
line-height: 11.72px;
text-align: left;
color: #D5D5D7;
margin-bottom: 0;
display: flex;
@media (max-width:1280px)  {
        
 margin-top: 0;       
}

span{
margin-left: 4px;
    color: #089641;
}

}

            }



            .investment{


                font-family: Roboto;
font-size: var(--f1 );
font-weight: 400;
line-height: 11.72px;
text-align: left;
color:  #D5D5D7;

p{font-size: var(--f1 );
    font-family: Roboto;
    color: #fff;
    font-weight: 400;
line-height: 11.72px;
text-align: left;
margin-top: 6px;
margin-bottom: 0;
@media (max-width:1280px)  {
 margin-top: 3px;       
        
}
}


            }
        }
    }
    .box1{
padding-right: 8%;
@media (max-width:992px){
             padding-right: 5%;      
}   
 }

    .box2{
      padding-left: 8%;
        margin-top: 36px;
        @media (max-width:1280px)  {
        margin-top: 25px;
        
        }
        @media (max-width:992px){
            padding-left: 5%;   
            margin-top: 16px;    
        }  
      
    }
}