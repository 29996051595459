.hero{height: 100%;
    // max-width: 90%;
    display: flex;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    /* margin-top: 190px; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    >*{
        animation: fadeInUp 2s alternate; 
        // animation-duration: 1s;
        // animation-iteration-count:infinite;
      }

    @media (max-width:1546px)  {
        // margin-top: 120px;
        
    }
    @media (max-width:1280px)  {
        // margin-top: 110px;
        
    }
    @media (max-width:767px)  {
        
    //   margin-top: 70px;  
       }

    //    h1{
    //     line-height: 90px;
    //    }
    
  
  
    h4{
        font-family: DM Sans;
font-size: var(--f7);
font-weight: 500;
line-height: 46.87px;



max-width: 650px;
margin: 0 auto;
margin-top: 30px;
margin-bottom: 15px;


@media (max-width:1546px){
line-height: 38px;
margin-bottom: 10px;
margin-top: 22px;
}
@media (max-width:1280px)  {
      line-height: 35px;
      margin-bottom: 5px;
      margin-top: 13px;  
      max-width: 550px;
        
}

    }
    p{
        position: relative;
        font-family: DM Sans;
font-size: var(--f4 );
font-weight: 400;
line-height: 23.44px;
text-align: center;
max-width: 570px;
margin: 0 auto;
margin-bottom: 50px;
@media (max-width:1546px){

margin-bottom: 40px;}
@media (max-width:1280px)  {
 max-width: 450px;       
        
}
@media (max-width:767px)  {
        
    line-height: 15px;   
   }


    }


    button{
        box-shadow: none;
        background: #EDFD72;
        color: #13242C;

        &:hover{
            background-color: transparent;
            border: 1px solid #EDFD72;
            color: #EDFD72;
        }
      

    }

   


}