.users{
    height: 100vh;
    background:url(../../Assets/locate-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: #fff;
    text-align: center;
    padding-top: 110px;

  

    @media (max-width:1280px){
      
       padding-top: 100px;
   } 
   
   @media  (max-width:1180px){
  height: 60vw;
   }

    @media (max-width:992px){
        padding-top: 75px; 
        height: 75vw;          
    } 
    @media (max-width:768px)  {
        height: 60vh;
      padding-top: 50px;
       } 
       @media (max-width:576px)  {
        padding-top: 35px;
        height: 70vh;  
       } 
>div{width: 70%;
    margin: 0 auto;
    animation:fadeInUp 2s both;
      animation-timeline: view();
      animation-range:  cover cover 30% ;
    @media (max-width:768px)  {
     width: 80%; 
       } 
    @media (max-width:576px)  {
        
      width: 90%; 
       } 
}


    p{margin-bottom: 50px;
        margin-top: 28px;
        font-family: DM Sans;
font-size: var(--f5 );
font-weight: 500;
line-height: 39px;
// letter-spacing: -0.6565263867378235px;
text-align: center;
@media (max-width:1546px){
   margin-top: 20px;
            }
            @media (max-width:1280px)  {
        
        margin-top: 15px;
        margin-bottom: 28px;
            }
            @media (max-width:992px){
              margin-top: 5px;
              margin-bottom: 20px;   
              line-height: 25px;  
            }   
    }

    .data{

        display: flex;
        justify-content: center;
        align-items: center;

        gap: 50px;

        h6{
            font-family: DM Sans;
font-size: var(--f8);
font-weight: 700;
line-height: 58.59px;
text-align: center;
color: #EDFD72;
@media (max-width:1280px)  {
 line-height: 32px;       
        
}
@media (max-width:992px){
    line-height: 26px;               
}   

        }
        p{
            font-family: DM Sans;
font-size: var(--f5 );
font-weight: 600;
line-height: 26.04px;
text-align: center;
margin: 0px;

// letter-spacing: .5px;
        }
    }
}