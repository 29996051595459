.navbar{
    background: transparent;
    color: #fff;
    padding-top: 50px;
    margin: 0 auto;
    display: flex;
justify-content: space-between;
align-items: center;
@media (max-width:1546px){
padding-top: 25px;
}
@media (max-width:1280px){
  padding-top: 20px;
}  

    .left{
        max-width: 140px;
        max-height: 26px;

        img{
            width: 100%;
        }

        @media (max-width:1546px){
max-width: 120px;

        }
        @media (max-width:1280px){
  max-width: 100px;
        } 
        @media (max-width:767px)  {
        
            max-width: 80px;   
           }
    }
    
    .right{
        ul{
            display: flex;
            gap:30px;
            list-style: none;

            li{
                font-family: DM Sans;
font-size: var(--f4 );
font-weight: 500;
line-height: 22.86px;
text-align: left;

button{font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    box-shadow: none;
    background: none;
    border: 1px solid #f7f7f7;
    color: #fff;

    &:hover{
        background: #EDFD72;
        color:#0F2028;
        border:1px solid #0F2028;
    }

}

            }

        }
    }
}